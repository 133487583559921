import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import LogoIcon from "../assets/icons/green.png";
import BottomArrow from "../assets/icons/bottom-arrow.svg";
import { Coins, PaymentContract } from "../utility/constant";
import InputText from "../components/InputText";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import {truncateAddress} from "../utils";
import TokenButton from "../components/TokenButton/TokenButton";
import * as axios from "axios";
import { useSearchParams } from 'react-router-dom';

const BuyCoin = ({ signer, account, networkName }) => {
  const [to, setTo] = useState(Coins[0]);
  const [from, setFrom] = useState({ label: "indexx500", icon: LogoIcon });
  const [token, setToken] = useState(0);
  const [cryptoToken, setCryptoToken] = useState(0);
  const [payment, setPayment] = useState(PaymentContract["BUSD"]);
  const [inputtoken, setInputtoken] = useState("");
  const [buyNowBtn, setBuyNowBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethodPrice, setPaymentMethodPrice] = useState(0);
  const [searchParams, _setSearchParams] = useSearchParams();
  searchParams.get("referralcode")

  const bnbChainlinkAddress = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  const wbtcChainlinkAddress = "0x264990fbd0A4796A3E3d8E37C4d5F87a3aCa5Ebf";
  const wethChainlinkAddress = "0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e";
  const icoAddress = "0xd0c7102e360ccfc6afd583d37ba94fb1a16aa7f9";

  const chainlinkABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const icoABI = [
    {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "purchaser",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "beneficiary",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "TokenPurchase",
      "type": "event"
    },
    {
      "stateMutability": "payable",
      "type": "fallback"
    },
    {
      "inputs": [],
      "name": "_setIndexxCryptoPrice",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "acceptedTokens",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_beneficiary",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "tokenAddress",
          "type": "address"
        }
      ],
      "name": "buyIndexxFromAnyBEP20",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_beneficiary",
          "type": "address"
        }
      ],
      "name": "buyIndexxFromBNB",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_discount",
          "type": "uint256"
        }
      ],
      "name": "changeDiscount",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_newAdmin",
          "type": "address"
        }
      ],
      "name": "changeIcoAdmin",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "closingTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "contributions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "discount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "hasClosed",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "icoAdmin",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "indexxCryptoPriceUSD",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "investorMinCap",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "openingTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "reserveWallet",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_openingTime",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_closingTime",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_discount",
          "type": "uint256"
        }
      ],
      "name": "scheduleSale",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "timelockContract",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "token",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "weiRaised",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "stateMutability": "payable",
      "type": "receive"
    }
  ];

  const paymentABI = [
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_upgradedAddress", type: "address" }],
      name: "deprecate",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_spender", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "deprecated",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_evilUser", type: "address" }],
      name: "addBlackList",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_from", type: "address" },
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "upgradedAddress",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "", type: "address" }],
      name: "balances",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "maximumFee",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "_totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "unpause",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_maker", type: "address" }],
      name: "getBlackListStatus",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { name: "", type: "address" },
        { name: "", type: "address" },
      ],
      name: "allowed",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "paused",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "who", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "pause",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "getOwner",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "owner",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transfer",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "newBasisPoints", type: "uint256" },
        { name: "newMaxFee", type: "uint256" },
      ],
      name: "setParams",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "amount", type: "uint256" }],
      name: "issue",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "amount", type: "uint256" }],
      name: "redeem",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { name: "_owner", type: "address" },
        { name: "_spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ name: "remaining", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "basisPointsRate",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "", type: "address" }],
      name: "isBlackListed",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_clearedUser", type: "address" }],
      name: "removeBlackList",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "MAX_UINT",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_blackListedUser", type: "address" }],
      name: "destroyBlackFunds",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { name: "_initialSupply", type: "uint256" },
        { name: "_name", type: "string" },
        { name: "_symbol", type: "string" },
        { name: "_decimals", type: "uint256" },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "amount", type: "uint256" }],
      name: "Issue",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "amount", type: "uint256" }],
      name: "Redeem",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "newAddress", type: "address" }],
      name: "Deprecate",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, name: "feeBasisPoints", type: "uint256" },
        { indexed: false, name: "maxFee", type: "uint256" },
      ],
      name: "Params",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, name: "_blackListedUser", type: "address" },
        { indexed: false, name: "_balance", type: "uint256" },
      ],
      name: "DestroyedBlackFunds",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "_user", type: "address" }],
      name: "AddedBlackList",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "_user", type: "address" }],
      name: "RemovedBlackList",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "owner", type: "address" },
        { indexed: true, name: "spender", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "from", type: "address" },
        { indexed: true, name: "to", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "Transfer",
      type: "event",
    },
    { anonymous: false, inputs: [], name: "Pause", type: "event" },
    { anonymous: false, inputs: [], name: "Unpause", type: "event" },
  ];

  const setInputValue = async (e) => {
    setLoading(true);
    let inputs = e.target.value;
    setInputtoken(inputs);
    let addr = "";
    let tokenPrice = 0;
    let spprice = 0;
    if (payment === PaymentContract["BNB"]) {
      addr = bnbChainlinkAddress;
    } else if (payment === PaymentContract["BUSD"]) {
      addr = "1";
    } else if (payment === PaymentContract["WBTC"]) {
      addr = wbtcChainlinkAddress;
    } else if (payment === PaymentContract["WETH"]) {
      addr = wethChainlinkAddress;
    } else {
      addr = "1";
    }

    let rpcProvider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    if (addr === "1") {
      tokenPrice = 1;
    } else if (addr !== "") {
      const tokenFeed = new ethers.Contract(addr, chainlinkABI, rpcProvider);
      await tokenFeed.latestRoundData().then((roundData) => {
        tokenPrice = roundData[1] / 100000000;
        console.log("token value: " + tokenPrice);
      });
    }
    console.log(inputs);
    spprice = await indexPrice();
    let rate = inputs * (tokenPrice / spprice);
    setToken(Math.round(rate * 100) / 100);
    setLoading(false);

  };

  const handlePayment = async (e) => {
    setLoading(true);
    const tokenContract = e;
    setPayment(PaymentContract[e.label]);

    setTo(e);
    let addr = "";   // Chainlink addresses
    let tokenPrice = 0;
    let spprice = 0;

    if (tokenContract.label === "BNB") {
      addr = bnbChainlinkAddress;
    } else if (tokenContract.label === "BUSD") {
      addr = "1";
    } else if (tokenContract.label === "WBTC") {
      addr = wbtcChainlinkAddress;
    } else if (tokenContract.label === "WETH") {
      addr = wethChainlinkAddress;
    } else {
      addr = "1";
    }

    let rpcProvider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    if (addr === "1") {
      tokenPrice = 1;
    } else if (addr !== "") {
      const tokenFeed = new ethers.Contract(addr, chainlinkABI, rpcProvider);
      await tokenFeed.latestRoundData().then((roundData) => {
        tokenPrice = roundData[1] / 100000000;
        console.log("token value: " + tokenPrice);
      });
    }
    
    console.log(inputtoken);
    spprice = await indexPrice();
    setPaymentMethodPrice(tokenPrice / spprice);
    let rate = inputtoken * (tokenPrice / spprice);
    setToken(Math.round(rate * 100) / 100);
    setLoading(false);

  };

  const indexPrice = async () => {
    console.log("Getting Price");

    if(cryptoToken !== 0){
      return cryptoToken;
    }

    let final = 0;    
    let rpcProvider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );

    let adaP = 0;
    let avaxP = 0;
    let bnbP = 0;
    let btcP = 0;
    let dogeP = 0;
    let dotP = 0;
    let ethP = 0;
    let maticP = 0;
    let solP = 0;
    let shibP = 0;
    let trxP = 0;
    let uniP = 0;
    let xrpP = 0;
    let ltcP = 0;
    let fttP = 0;

    let ada = "0xa767f745331D267c7751297D982b050c93985627";
    let avax = "0x5974855ce31EE8E1fff2e76591CbF83D7110F151";
    let bnb = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
    let btc = "0x264990fbd0A4796A3E3d8E37C4d5F87a3aCa5Ebf";
    let doge = "0x3AB0A0d137D4F946fBB19eecc6e92E64660231C8";
    let dot = "0xC333eb0086309a16aa7c8308DfD32c8BBA0a2592";
    let eth = "0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e";
    let matic = "0x7CA57b0cA6367191c94C8914d7Df09A57655905f";
    let sol = "0x0E8a53DD9c13589df6382F13dA6B3Ec8F919B323";
    let shib = "0xA615Be6cb0f3F36A641858dB6F30B9242d0ABeD8";
    let trx = "0xF4C5e535756D11994fCBB12Ba8adD0192D9b88be";
    let uni = "0xb57f259E7C24e56a1dA00F66b55A5640d9f9E7e4";
    let xrp = "0x93A67D414896A280bF8FFB3b389fE3686E014fda";
    let ltc = "0x74E72F37A8c415c8f1a98Ed42E78Ff997435791D";
    let ftt = "0x38E05754Eb00171cBE72bA1eE792933d6e8d2891";

    const adaC = new ethers.Contract(ada, chainlinkABI, rpcProvider);
    await adaC.latestRoundData().then((roundData) => {
      adaP = roundData[1] / 100000000;
    });
    const avaxC = new ethers.Contract(avax, chainlinkABI, rpcProvider);
    await avaxC.latestRoundData().then((roundData) => {
      avaxP = roundData[1] / 100000000;
    });
    const bnbC = new ethers.Contract(bnb, chainlinkABI, rpcProvider);
    await bnbC.latestRoundData().then((roundData) => {
      bnbP = roundData[1] / 100000000;
    });
    const btcC = new ethers.Contract(btc, chainlinkABI, rpcProvider);
    await btcC.latestRoundData().then((roundData) => {
      btcP = roundData[1] / 100000000;
    });
    const dogeC = new ethers.Contract(doge, chainlinkABI, rpcProvider);
    await dogeC.latestRoundData().then((roundData) => {
      dogeP = roundData[1] / 100000000;
    });
    const dotC = new ethers.Contract(dot, chainlinkABI, rpcProvider);
    await dotC.latestRoundData().then((roundData) => {
      dotP = roundData[1] / 100000000;
    });
    const ethC = new ethers.Contract(eth, chainlinkABI, rpcProvider);
    await ethC.latestRoundData().then((roundData) => {
      ethP = roundData[1] / 100000000;
    });
    const maticC = new ethers.Contract(matic, chainlinkABI, rpcProvider);
    await maticC.latestRoundData().then((roundData) => {
      maticP = roundData[1] / 100000000;
    });
    const solC = new ethers.Contract(sol, chainlinkABI, rpcProvider);
    await solC.latestRoundData().then((roundData) => {
      solP = roundData[1] / 100000000;
    });
    const shibC = new ethers.Contract(shib, chainlinkABI, rpcProvider);
    await shibC.latestRoundData().then((roundData) => {
      shibP = roundData[1] / 100000000;
    });
    const trxC = new ethers.Contract(trx, chainlinkABI, rpcProvider);
    await trxC.latestRoundData().then((roundData) => {
      trxP = roundData[1] / 100000000;
    });
    const uniC = new ethers.Contract(uni, chainlinkABI, rpcProvider);
    await uniC.latestRoundData().then((roundData) => {
      uniP = roundData[1] / 100000000;
    });
    const xrpC= new ethers.Contract(xrp, chainlinkABI, rpcProvider);
    await xrpC.latestRoundData().then((roundData) => {
      xrpP = roundData[1] / 100000000;
    });
    const ltcC = new ethers.Contract(ltc, chainlinkABI, rpcProvider);
    await ltcC.latestRoundData().then((roundData) => {
      ltcP = roundData[1] / 100000000;
    });
    const fttC = new ethers.Contract(ftt, chainlinkABI, rpcProvider);
    await fttC.latestRoundData().then((roundData) => {
      fttP = roundData[1] / 100000000;
    });
    
    final = (ethP + btcP)*0.1 + (bnbP + xrpP + adaP + solP + dotP)*0.8 + 
        (dogeP + maticP + avaxP + uniP + shibP + trxP + ltcP + fttP )* 0.5 ;

    setCryptoToken(Math.round(final * 100) / 100000);    
    return Math.round(final * 100) / 100000;
  };

  const payCrypto = async () => {
    if(token < 1) {
      toast.error("Minimum Purchase 1 Indexx Token");
      return
    }

    try {
      setLoading(true);
      const ico_contract = new ethers.Contract(icoAddress, icoABI, signer);
      let tx;
      if (payment === PaymentContract["BNB"]) {
        tx = await ico_contract.buyIndexxFromBNB(account, {
          value: ethers.utils.parseUnits(inputtoken, "ether"),
        });
      } else if (payment === "stripe") {
        window.open(
          'https://buy.stripe.com/7sI2994hyg87aekcMN'
        );
      }else if (payment !== "") {
        tx = await ico_contract.buyIndexxFromAnyBEP20(
          account,
          ethers.utils.parseUnits(inputtoken, "ether"),
          payment
        );
      }
      console.log(`Transaction hash: ${tx.hash}`);
      const receipts = await tx.wait();
      console.log(`Transaction confirmed in block ${receipts.blockNumber}`);
      console.log(`Gas used: ${receipts.gasUsed.toString()}`);
      setBuyNowBtn(false);
      toast.success("Payment Successful");
      setLoading(false);
      const affiliateCode = searchParams.get("referralcode");
      if (affiliateCode !=  undefined || affiliateCode != null) {
        const paymentTypeUsed = await getPaymentUsed(payment);
        console.log(paymentTypeUsed)
        let userPurchaseDetails = {
          txHash: tx.hash,
          affiliateCode: affiliateCode,
          totalTokensPurchased: token,
          tokensNamePurchased: "IndexxCrypto",
          userURLtoPurchase: window.location.href,
          paymentTypeUsed: paymentTypeUsed,
          paymentTokenPrice: paymentMethodPrice,
          userIP: "120.434.1.2",
          userWalletAddress: account,
        };
        //https://api.indexx.finance/api/v1/update/purchasedetails
        let updatePurchaseDetails = await axios.post(
          "https://1b52-49-207-196-183.ngrok.io/api/v1/update/purchasedetails",
          userPurchaseDetails
        );
        console.log(updatePurchaseDetails, "updatePurchaseDetails");
        if (updatePurchaseDetails.status === 200) {
          alert(updatePurchaseDetails.data.message)
          window.location.href = "https://www.tokens.indexx.ai/aboute5e75cc8";
        }
      } else {
        window.location.href = "https://www.tokens.indexx.ai/aboute5e75cc8";
      }

    } catch (error) {
      console.log('error', error)
      // TODO Error handle with toast message
      if (error?.data?.message != undefined)
        toast.error(error?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (payment !== "stripe"){
      toast.error("Transaction Failed. Please again later!");
        }
      setLoading(false);
    }
  };

  const getPaymentUsed = async (payment) => {
    try {
      switch (payment) {
        case PaymentContract["BNB"]:
          return "BNB";
        case PaymentContract["BUSD"]:
          return "BUSD";
        case PaymentContract["WBTC"]:
          return "WBTC";
        case PaymentContract["WETH"]:
          return "WETH";
        default:
          return "Stripe";
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const approve = async () => {
    if(token < 1) {
      toast.error("Minimum Purchase 1 Indexx Token");
      return
    }

    try {
      setLoading(true);
      const paymentContract = new ethers.Contract(payment, paymentABI, signer);
      const tx = await paymentContract.approve(
        icoAddress,
        ethers.utils.parseUnits(inputtoken, "ether")
      );
      setLoading(true);
      const receipt = await tx.wait();
      setBuyNowBtn(true);
      setLoading(false);
      console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
      console.log(`Gas used: ${receipt.gasUsed.toString()}`);
      toast.success("Approved");
      setLoading(false);
    } catch (error) {
      // TODO Error handle with toast message
      toast.error(error);
      setLoading(false);

    }
  };

  return (
    <div>
      <div
        style={{
          width: "60%",
          margin: "auto",
          textAlign: "center",
          marginTop: 20,
        }}
      >
        <figure>
          <img
            src={LogoIcon}
            style={{ width: 64, height: 64 }}
            alt="indexx logo"
          />
          <figcaption style={{ color: "#05b64f", fontSize: "larger", fontWeight: "bold" }}>IndexxCrypto(INXC)</figcaption>
        </figure>
        <h3 style={{ marginTop: 20, color: "#05b64f", marginBottom: 0 }}>
          SWAP
        </h3>
        <p style={{ color: "#05b64f" }}>Trade token in an instant</p>

        <p style={{ color: "#05b64f" }}>{`Account: ${truncateAddress(account)}`}({networkName})</p>

        { networkName !== "bnb" &&
            <div className="warningBarContainer">
              <p className="warningBar">Wrong Network! Please switch to BSC Smart Chain and refresh the page</p>
            </div>}

        <p style={{ color: "#05b64f" }}>Choose Payment Token</p>

        <TokenButton selectedToken={to} onChange={(coin) => handlePayment(coin)}/>

        <InputText
          icon={to.icon}
          value={to.label}
          position={["left"]}
          onChange={(e) => handlePayment(e)}
          inputtoken={inputtoken}
          handleChange={setInputValue}
        />
        <img
          src={BottomArrow}
          alt="bottom-arrow"
          style={{ width: 32, height: 32, marginBottom: 15 }}
        />
        {/* <InputText icon={from.icon} value={from.label} onChange={(item) => setFrom(item)} /> */}
        <div
          style={{ display: "flex", justifyContent: "center", marginRight: 50 }}
        >
          <div style={{ display: "grid" }}>
            <img
              src={from.icon}
              alt="icon"
              style={{ height: 32, width: 32, margin: "auto" }}
            />
            <span style={{ fontSize: 10, margin: 0, color: "#808080" }}>
              {/* {from.label} */}
              INXC
            </span>
          </div>
          <div>
            <Form.Control
              type="text"
              id="inputBusd"
              value={token}
              aria-describedby="passwordHelpBlock"
              disabled
            />
          </div>
        </div>
      </div>
      {to.label === "BNB" || to.label === "Stripe" || buyNowBtn ? (
        <div className="buy-now-btn" onClick={payCrypto}>
          {loading ? <Spinner animation="border" variant="light" /> : `BUY`}
        </div>
      ) : (
        <div className="buy-now-btn" onClick={approve}>
          {loading ? <Spinner animation="border" variant="light" /> : `APPROVE`}
        </div>
      )}
      <div>
        <p className="guide-lines text-center">
          Tokens bought with discount will be released as per time lock
          schedule with KYC guidelines
        </p>
      </div>
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </div>
  );
};

export default BuyCoin;
